import L_HTML5 from "../../assets/img/skills/html-5.svg";
import L_CSS3 from "../../assets/img/skills/css-3.svg";
import L_SASS from "../../assets/img/skills/sass.svg";
//import L_BOOTSTRAP from "../../assets/img/skills/bootstrap-4.svg";
import L_REACT from "../../assets/img/skills/react.svg";
import L_NEXT from "../../assets/img/skills/nextjs.svg";
//import L_REDUX from "../../assets/img/skills/redux.svg";
//import L_REACT_ROUTER from "../../assets/img/skills/react-router.svg";
//import L_REACT_BOOTSTRAP from "../../assets/img/skills/react-bootstrap.svg";
//import L_STYLED_COMPONENTS from "../../assets/img/skills/styled-components.svg";
import L_NODE_JS from "../../assets/img/skills/nodejs.svg";
import L_EXPRESS from "../../assets/img/skills/express.svg";
import L_MONGODB from "../../assets/img/skills/mongodb.svg";
import L_SQLITE from "../../assets/img/skills/sqlite.svg";
import L_FIREBASE from "../../assets/img/skills/firebase.svg";
import L_GIT from "../../assets/img/skills/git.svg";
import L_HEROKU from "../../assets/img/skills/heroku.svg";
import L_VERCEL from "../../assets/img/skills/vercel.svg";
import L_GITHUB_PAGES from "../../assets/img/skills/github.svg";
import L_JAVASCRIPT from "../../assets/img/skills/js.svg";
import L_TYPESCRIPT from "../../assets/img/skills/typescript.svg";

export const skills = {
	frontend: [
		{
			imgAltText: "HTML 5",
			imgSrc: L_HTML5,
			skillName: "HTML5",
		},
		{
			imgAltText: "CSS 3",
			imgSrc: L_CSS3,
			skillName: "CSS3",
		},
		{
			imgAltText: "JavaScript",
			imgSrc: L_JAVASCRIPT,
			skillName: "JavaScript",
		},
		{
			imgAltText: "Sass",
			imgSrc: L_SASS,
			skillName: "Sass",
		},
		// {
		// 	imgAltText: "Bootstrap",
		// 	imgSrc: L_BOOTSTRAP,
		// 	skillName: "Bootstrap",
		// },
		{
			imgAltText: "React JS",
			imgSrc: L_REACT,
			skillName: "React JS",
		},
		{
			imgAltText: "Next JS",
			imgSrc: L_NEXT,
			skillName: "Next JS",
		},
		// {
		// 	imgAltText: "Redux",
		// 	imgSrc: L_REDUX,
		// 	skillName: "Redux",
		// },
		// {
		// 	imgAltText: "React Router",
		// 	imgSrc: L_REACT_ROUTER,
		// 	skillName: "React Router",
		// },

		// {
		// 	imgAltText: "React Bootstrap",
		// 	imgSrc: L_REACT_BOOTSTRAP,
		// 	skillName: "React Bootstrap",
		// },
		// {
		// 	imgAltText: "styled-components",
		// 	imgSrc: L_STYLED_COMPONENTS,
		// 	skillName: "styled-components",
		// },
	],

	backend: [
		{
			imgAltText: "Node.js",
			imgSrc: L_NODE_JS,
			skillName: "Node.js",
		},
		{
			imgAltText: "Express",
			imgSrc: L_EXPRESS,
			skillName: "Express",
		},
	],
	hostingPlatforms: [
		{
			imgAltText: "Heroku",
			imgSrc: L_HEROKU,
			skillName: "Heroku",
		},

		{
			imgAltText: "Vercel",
			imgSrc: L_VERCEL,
			skillName: "Vercel",
		},

		{
			imgAltText: "GitHub Pages",
			imgSrc: L_GITHUB_PAGES,
			skillName: "GitHub Pages",
		},
	],
	programmingLanguages: [
		{
			imgAltText: "JavaScript",
			imgSrc: L_JAVASCRIPT,
			skillName: "JavaScript",
		},
		{
			imgAltText: "TypeScript",
			imgSrc: L_TYPESCRIPT,
			skillName: "TypeScript",
		},
	],
	databases: [
		{
			imgAltText: "MongoDB",
			imgSrc: L_MONGODB,
			skillName: "MongoDB",
		},
		{
			imgAltText: "SQLite",
			imgSrc: L_SQLITE,
			skillName: "SQLite",
		},
		{
			imgAltText: "Firebase",
			imgSrc: L_FIREBASE,
			skillName: "Firebase",
		},
	],
	versionControl: [
		{
			imgAltText: "GIT",
			imgSrc: L_GIT,
			skillName: "GIT",
		},
	],
};

import React from "react";
import Container from "react-bootstrap/Container";

//Components
import MyNavbar from "./components/navbar/navbar";
import MyCarousel from "./components/carousel/carousel";

import MyTitleMessage from "./components/title-message/title-message";
import About from "./pages/about/about";
import Skills from "./pages/skills/skills";
import TimeLine from "./components/projects/projects";
import ContactForm from "./pages/contact-form/contact-form.jsx";

import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import FooterPanel from "./components/footer/footer";

import "./App.scss";

class App extends React.Component {
	render() {
		return (
			<div className='App' style={{ position: "relative" }}>
				<MyCarousel />
				<MyTitleMessage />
				<MyNavbar />

				<div>
					{/* <Fade right duration={1600}> */}
					<About />
					{/* </Fade> */}
				</div>

				<Container className='container-box rounded'>
					<Slide left duration={1600}>
						<hr />
						<Skills />
					</Slide>
				</Container>

				<div>
					{/* <Slide bottom duration={1600}> */}
					<Container className='container-box rounded'>
						<hr />
					</Container>
					<TimeLine />
					{/* </Slide> */}
				</div>

				<Container className='container-box rounded'>
					<Fade right duration={1600}>
						<hr />
						<ContactForm />
					</Fade>
				</Container>

				<hr />
				<FooterPanel />
			</div>
		);
	}
}

export default App;

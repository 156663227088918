import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./about.scss";
import Profile from "../../assets/img/about/profile.png";
import Image from "react-bootstrap/Image";
import Pdf from "../../assets/resume/Resume-Franciane-Ribeiro-Póvoa.pdf";

const About = () => {
	return (
		<div id='about'>
			<div className='about'>
				<h1 className='pt-3 text-center font-details-a pb-3'>ABOUT ME</h1>
				<Container>
					<Row className='pt-3 pb-5 align-items-center'>
						<Col xs={12} md={6}>
							<Row className='justify-content-center mb-2 mr-2 '>
								<Image
									className='profile justify-content-end'
									alt='profile'
									src={Profile}
									thumbnail
									fluid
								/>
							</Row>
						</Col>
						<Col xs={12} md={6}>
							<Row className=' align-items-start p-2 my-details rounded'>
								Hi there, I am <strong>&nbsp;Fran!</strong>
								A Brazilian living in Bergen, Norway. <br />
								My focus is to write clean, elegant, and efficient code.
								<br /> That helps in creating interactive experiences and
								functional interfaces using React.
								<br /> <br />
								<Col className='d-flex justify-content-center flex-wrap'>
									<div>
										<span className='"d-flex justify-content-center'>
											<a className='cta-btn cta-btn' href='#contact'>
												Let's talk
											</a>
										</span>
									</div>
									&nbsp;
									<div>
										<span className='"d-flex justify-content-center'>
											<a
												className='cta-btn cta-btn'
												href={Pdf}
												target='_blank'
												rel='noopener noreferrer'>
												My Resume
											</a>
										</span>
									</div>
									&nbsp;
									<div>
										<span className='"d-flex justify-content-center'>
											<a
												className='cta-btn cta-btn'
												href='https://github.com/francianepovoa'
												target='_blank'
												rel='noopener noreferrer'>
												GitHub
											</a>
										</span>
									</div>
									&nbsp;
									<div>
										<span className='"d-flex justify-content-center'>
											<a
												className='cta-btn cta-btn'
												href='https://www.linkedin.com/in/francianerpovoa/'
												target='_blank'
												rel='noopener noreferrer'>
												LinkedIn
											</a>
										</span>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default About;

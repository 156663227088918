import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Slide1 from "../../assets/img/about/first.jpg";
import Slide2 from "../../assets/img/about/second.jpg";
import "./carousel.scss";

import ScrollDown from "../scroll-down/scroll-down";

const MyCarousel = () => {
	return (
		<div id='home'>
			<Carousel controls={false} indicators interval={7500} pause={false}>
				<Carousel.Item>
					<img
						className='d-block w-100 custom-img'
						src={Slide2}
						alt='First slide'
					/>
					{/* <div className='photo'>
						<a
							className='img'
							href='https://unsplash.com/@georgie_cobbs?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
							{" "}
							Photo by Georgie Cobbs
						</a>{" "}
						<a
							className='img'
							href='https://unsplash.com/@francianepovoa/likes?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
							{" "}
							on Unsplash
						</a>
					</div> */}
				</Carousel.Item>
				<Carousel.Item>
					<img
						className='d-block w-100 custom-img'
						src={Slide1}
						alt='Second slide'
					/>
					{/* <div className='photo'>
						<a
							className='img'
							href='https://unsplash.com/@jessbaileydesigns?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
							Photo by Jess Bailey
						</a>
						<a
							className='img'
							href='https://unsplash.com/@francianepovoa/likes?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
							on Unsplash
						</a>
					</div> */}
				</Carousel.Item>
			</Carousel>
			<ScrollDown />
		</div>
	);
};

export default MyCarousel;

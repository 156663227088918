import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./navbar.scss";

const MyNavbar = () => {
	return (
		<div>
			<Navbar
				fixed='top'
				variant='light'
				expand='md'
				className='animate-navbar nav-theme justify-content-end'>
				<Navbar.Brand className='mr-auto' href='#home'>
					Franciane | Web Dev
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='responsive-navbar-nav' />
				<Navbar.Collapse id='responsive-navbar-nav'>
					<Nav className='ml-auto '>
						<Nav.Link href='#home'>Home</Nav.Link>
						<Nav.Link href='#about'>About</Nav.Link>
						<Nav.Link href='#skills'>Skills</Nav.Link>
						<Nav.Link href='#projects'>Projects</Nav.Link>
						<Nav.Link href='#contact'>Contact</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
};

export default MyNavbar;

import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./contact-form.scss";

const ContactForm = () => {
	return (
		<div id='contact'>
			<h1 className='pt-3  color text-center font-details-b pb-3'>
				CONTACT ME
			</h1>
			<Jumbotron className='contact-jumbotron'>
				<Row>
					<Col className='d-flex justify-content-center flex-wrap'>
						<div className='m-2'>
							<a
								href='mailto:franciane@povoa.no'
								target='_blank'
								rel='noopener noreferrer'>
								<Button variant='outline-info' title='franciane@povoa.no'>
									<i className='fas fa-envelope-square'></i> Email
								</Button>
							</a>
						</div>
						<div className='m-2'>
							<a
								href='https://www.linkedin.com/in/francianerpovoa/'
								target='_blank'
								rel='noopener noreferrer'>
								<Button variant='outline-primary' title='LinkedIn'>
									<i className='fab fa-linkedin'></i> LinkedIn
								</Button>
							</a>
						</div>

						<div className='m-2'>
							<a
								href='https://github.com/francianepovoa'
								target='_blank'
								rel='noopener noreferrer'>
								<Button variant='outline-dark' title='Other projects'>
									<i className='fab fa-github-square'></i> GitHub
								</Button>
							</a>
						</div>
					</Col>
				</Row>
			</Jumbotron>
		</div>
	);
};

export default ContactForm;

import React from "react";
import "./footer.scss";

const FooterPanel = () => {
	return (
		<div>
			<div className='py-2 text-center footer-style'>
				© FRANCIANE POVOA &nbsp; {new Date().getFullYear()}
			</div>
		</div>
	);
};

export default FooterPanel;

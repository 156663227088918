import React from "react";
import Typewriter from "typewriter-effect";
import styled from "styled-components";

const MyTitleMessage = styled.h1`
	position: absolute;
	width: 100%;
	top: 13rem;
	z-index: 1;
	text-align: center;

	strong {
		font-size: 1.25em;
	}
	div {
		color: ${(props) => props.theme.textColor};

		text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.192);
		font-weight: 100;
		letter-spacing: 7px;

		.main {
			font-size: 3.5rem;
			line-height: 1.5;
		}

		.sub {
			font-size: 3rem;
			font-weight: 400;
			letter-spacing: 0.5rem;
			line-height: 1.5;
			display: inline;
			color: #02c0a0;
		}
	}

	/* Extra small devices (phones, 600px and down) */
	@media only screen and (max-width: 600px) {
		/* top: 8.5rem !important; */

		.main {
			font-size: 2rem !important;
		}
		.sub {
			font-size: 1.5rem !important;
		}
	}

	/* Small devices (portrait tablets and large phones, 600px and up) */
	@media only screen and (min-width: 600px) {
		top: 8rem !important;
		.main {
			font-size: 3rem !important;
		}
		.sub {
			font-size: 2.5rem !important;
		}
	}

	/* Medium devices (landscape tablets, 768px and up) */
	@media only screen and (min-width: 768px) {
		top: 10rem !important;

		.main {
			font-size: 3.1rem !important;
		}
		.sub {
			font-size: 2.5rem !important;
		}
	}

	/* Large devices (laptops/desktops, 992px and up) */
	@media only screen and (min-width: 992px) {
		top: 11rem !important;
		.main {
			font-size: 3.2rem !important;
		}
		.sub {
			font-size: 2.7rem !important;
		}
	}
`;

const TitleMessage = () => (
	<MyTitleMessage>
		<div className='titleMessage'>
			<div className='heading'>
				<div className='main text-center mb-3'>
					Hi, I am
					<br />
					<span>
						<strong>Franciane Póvoa </strong>
					</span>
				</div>
				<br />
				<div className='sub'>
					<Typewriter
						options={{
							strings: ["Web", "Front-end", "Back-end"],
							autoStart: true,
							loop: true,
							delay: 200,
						}}
					/>
					Developer
				</div>
			</div>
		</div>
	</MyTitleMessage>
);

export default TitleMessage;

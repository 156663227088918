import React from "react";
import { Timeline, Events, UrlButton, ImageEvent } from "@merc/react-timeline";

// projects
import L_PODCASTR from "../../assets/img/projects/podcastr.png";
import L_CRWN from "../../assets/img/projects/crwn.png";
import L_MOVE from "../../assets/img/projects/move.png";
import L_CRYPTODASH from "../../assets/img/projects/cryptodash.png";
import L_BACKEND from "../../assets/img/projects/backend.jpg";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

// skills
import L_TYPESCRIPT from "../../assets/img/skills/typescript.svg";
import L_JAVASCRIPT from "../../assets/img/skills/js.svg";
import L_SASS from "../../assets/img/skills/sass.svg";
import L_REACT from "../../assets/img/skills/react.svg";
import L_NEXT from "../../assets/img/skills/nextjs.svg";
import L_NODE_JS from "../../assets/img/skills/nodejs.svg";
import L_EXPRESS from "../../assets/img/skills/express.svg";
import L_SQLite from "../../assets/img/skills/sqlite.svg";
import L_FIREBASE from "../../assets/img/skills/firebase.svg";
import Image from "react-bootstrap/Image";
import L_REDUX from "../../assets/img/skills/redux.svg";
import L_HTML5 from "../../assets/img/skills/html-5.svg";
import L_CSS3 from "../../assets/img/skills/css-3.svg";
import L_STYLED from "../../assets/img/skills/styled-components.svg";
import L_GIT from "../../assets/img/skills/github-api.svg";
import L_VERCEL from "../../assets/img/skills/vercel.svg";
import L_HEROKU from "../../assets/img/skills/heroku.svg";

import "./projects.scss";

const TimeLine = () => {
	return (
		<div id='projects'>
			<h1 className='pt-3 text-center font-details-b pb-3'>PROJECTS</h1>
			<Timeline>
				<Events>
					{/* Project: Crwn- Clothing */}
					<ImageEvent
						date='Jun 2021'
						className='text-center '
						text='Crwn- Clothing'
						src={L_CRWN}
						alt='Crwn- Clothing'>
						<div className='d-flex justify-content-between flex-column mt-1'>
							<div>
								<Accordion>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey='0'
											className='p-2 text-center accordian-main'>
											PROJECT DETAILS
										</Accordion.Toggle>

										<Accordion.Collapse eventKey='0' className='text-left'>
											<Card.Body>
												<strong>Description:</strong> This is an E-commerce
												application similar to Shopify using React, Redux, React
												Router, Context API, Firebase, Stripe.
												<hr />
												<strong>Features:</strong>
												<ul className='list-styles pt-1'>
													<li>Powered by React.js </li>
													<li>Redux</li>
													<li>React Hooks</li>
													<li>Firebase</li>
													<li>Stripe API</li>
												</ul>
												<hr />
												<strong>Tech used:</strong>
												<div className='container'>
													<div>
														<Image
															src={L_REACT}
															alt='React'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_SASS}
															alt='Sass'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_REDUX}
															alt='Redux'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_FIREBASE}
															alt='Vercel'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_HEROKU}
															alt='Heroku'
															rounded
															className='image-style-1 m-1'></Image>{" "}
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
							<div className='d-flex justify-content-center flex-nowrap text-center'>
								<UrlButton
									href='https://crown-live-clothes.herokuapp.com/'
									target='_blank'>
									SEE LIVE
								</UrlButton>
								<UrlButton
									href='https://github.com/francianepovoa/crwn-clothing'
									target='_blank'>
									SOURCE CODE
								</UrlButton>
							</div>
						</div>
					</ImageEvent>

					{/* Project: Podcastr */}
					<ImageEvent
						date='April 2021'
						className='text-center '
						text='Podcastr'
						src={L_PODCASTR}
						alt='Podcastr'>
						<div className='d-flex justify-content-between flex-column mt-1'>
							<div>
								<Accordion>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey='0'
											className='p-2 text-center accordian-main'>
											PROJECT DETAILS
										</Accordion.Toggle>

										<Accordion.Collapse eventKey='0' className='text-left'>
											<Card.Body>
												<strong>Description:</strong> It is a fictional podcast
												page. On the homepage, you can see all the episodes
												already released. In each title, you will be redirected
												to the episode page.
												<hr />
												<strong>Features:</strong>
												<ul className='list-styles pt-1'>
													<li>Powered by Next.js </li>
													<li>A web application using ContextAPI</li>
												</ul>
												<hr />
												<strong>Tech used:</strong>
												<div className='container'>
													<div>
														<Image
															src={L_TYPESCRIPT}
															alt='TYPESCRIPT'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_SASS}
															alt='Sass'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_NEXT}
															alt='Next.js'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_VERCEL}
															alt='Vercel'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
							<div className='d-flex justify-content-between flex-nowrap text-center'>
								<UrlButton
									href='https://podcastr-francianepovoa.vercel.app/'
									target='_blank'>
									SEE LIVE
								</UrlButton>
								<UrlButton
									href='https://github.com/francianepovoa/podcastr'
									target='_blank'>
									SOURCE CODE
								</UrlButton>
							</div>
						</div>
					</ImageEvent>

					{/* Project: customer-support-chat */}
					<ImageEvent
						date='April 2021'
						className='text-center '
						text='Customer Support Chat'
						src={L_BACKEND}
						alt='customer-support-chat'>
						<div className='d-flex justify-content-between flex-column mt-1'>
							<div>
								<Accordion>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey='0'
											className='p-2 text-center accordian-main'>
											PROJECT DETAILS
										</Accordion.Toggle>

										<Accordion.Collapse eventKey='0' className='text-left'>
											<Card.Body>
												<strong>Description:</strong> Chat is an API for
												customer service in real-time. It is an application that
												creates a call (message) from the user to the support,
												where the messages are answered in real-time and saved
												in a database in case the user wants to return to the
												subject addressed.
												<hr />
												<strong>Features:</strong>
												<ul className='list-styles pt-1'>
													<li>Node.js and Express for route management </li>
													<li>TypeORM for data manipulation</li>
													<li>
														Websocket for a persistent connection between a
														client and server
													</li>
												</ul>
												<hr />
												<strong>Tech used:</strong>
												<div className='container'>
													<div>
														<Image
															src={L_TYPESCRIPT}
															alt='TYPESCRIPT'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_NODE_JS}
															alt='Node.js'
															rounded
															className='image-style-1 m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_EXPRESS}
															alt='Express'
															rounded
															className='image-style-1 m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_SQLite}
															alt='SQLite'
															rounded
															className='image-style-1 m-1'></Image>{" "}
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
							<div className='d-flex justify-content-center flex-nowrap text-center'>
								<UrlButton
									href='https://github.com/francianepovoa/customer-support-chat'
									target='_blank'>
									SOURCE CODE
								</UrlButton>
							</div>
						</div>
					</ImageEvent>

					{/* Project: Move.it */}
					<ImageEvent
						date='February 2021'
						className='text-center '
						text='Move.it'
						src={L_MOVE}
						alt='Move.it'>
						<div className='d-flex justify-content-between flex-column mt-1'>
							<div>
								<Accordion>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey='0'
											className='p-2 text-center accordian-main'>
											PROJECT DETAILS
										</Accordion.Toggle>

										<Accordion.Collapse eventKey='0' className='text-left'>
											<Card.Body>
												<strong>Description:</strong> Move-it suggests
												challenges for those who work a lot of time sitting at
												the computer.
												<hr />
												<strong>Features:</strong>
												<ul className='list-styles pt-1'>
													<li>Powered by Next.js </li>
													<li>React Hooks</li>
													<li>State management using ContextAPI</li>
												</ul>
												<hr />
												<strong>Tech used:</strong>
												<div className='container'>
													<div>
														<Image
															src={L_TYPESCRIPT}
															alt='Typescript'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_NEXT}
															alt='next.js'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_CSS3}
															alt='CSS'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>

													<div>
														<Image
															src={L_VERCEL}
															alt='Vercel'
															rounded
															className='image-style-1 m-1'></Image>{" "}
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
							<div className='d-flex justify-content-center flex-nowrap text-center'>
								{/* <UrlButton
										className='mobile'
										href='https://moveit-next-francianepovoa.vercel.app/'
										target='_blank'>
										SEE LIVE
									</UrlButton> */}

								<UrlButton
									href='https://github.com/francianepovoa/moveit-next.js'
									target='_blank'>
									SOURCE CODE
								</UrlButton>
							</div>
						</div>
					</ImageEvent>

					{/* Project: Survey research email*/}
					<ImageEvent
						date='February 2021'
						className='text-center '
						text='Survey research email'
						src={L_BACKEND}
						alt='Survey research email'>
						<div className='d-flex justify-content-between flex-column mt-1'>
							<div>
								<Accordion>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey='0'
											className='p-2 text-center accordian-main'>
											PROJECT DETAILS
										</Accordion.Toggle>

										<Accordion.Collapse eventKey='0' className='text-left'>
											<Card.Body>
												<strong>Description:</strong> The NPS-Survey is an API
												that consists of calculating the company's NPS. In this
												application, we register users, register surveys, send
												emails to users to answer satisfaction surveys and with
												that, we can perform the NPS calculation.
												<hr />
												<strong>Features:</strong>
												<ul className='list-styles pt-1'>
													<li>Node.js and Express for route management </li>
													<li>TypeORM for data manipulation</li>
													<li>Automated tests</li>
												</ul>
												<hr />
												<strong>Tech used:</strong>
												<div className='container'>
													<div>
														<Image
															src={L_TYPESCRIPT}
															alt='TYPESCRIPT'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_NODE_JS}
															alt='Node.js'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_EXPRESS}
															alt='Express'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_SQLite}
															alt='SQLite'
															rounded
															className='image-style-1 m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_GIT}
															alt='Git'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
							<div className='d-flex justify-content-center flex-nowrap text-center'>
								<UrlButton
									href='https://github.com/francianepovoa/survey-research'
									target='_blank'>
									SOURCE CODE
								</UrlButton>
							</div>
						</div>
					</ImageEvent>

					{/* Project: CryptoDash*/}
					<ImageEvent
						date='December 2020'
						className='text-center '
						text='CryptoDash'
						src={L_CRYPTODASH}
						alt='CryptoDash'>
						<div className='d-flex justify-content-between flex-column mt-1'>
							<div>
								<Accordion>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey='0'
											className='p-2 text-center accordian-main'>
											PROJECT DETAILS
										</Accordion.Toggle>

										<Accordion.Collapse eventKey='0' className='text-left'>
											<Card.Body>
												<strong>Description:</strong>This is a Cryptocurrency
												Dashboard project using React.js, Context API,
												styled-components & CSS Grid.
												<hr />
												<strong>Features:</strong>
												<ul className='list-styles pt-1'>
													<li>Powered by React.js </li>
													<li>CryptoCompare</li>
													<li>Highcharts</li>
												</ul>
												<hr />
												<strong>Tech used:</strong>
												<div className='container'>
													<div>
														<Image
															src={L_HTML5}
															alt='HTML'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_CSS3}
															alt='CSS'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_STYLED}
															alt='Styled-components'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_JAVASCRIPT}
															alt='React.js'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_REACT}
															alt='React.js'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_HEROKU}
															alt='Heroku'
															rounded
															className='image-style-1 m-1'></Image>{" "}
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
							<div className='d-flex justify-content-between flex-nowrap text-center'>
								<UrlButton
									href='https://cryptodash-live.herokuapp.com/'
									target='_blank'>
									SEE LIVE
								</UrlButton>
								<UrlButton
									href='https://github.com/francianepovoa/cryptodash'
									target='_blank'>
									SOURCE CODE
								</UrlButton>
							</div>
						</div>
					</ImageEvent>

					{/* Project: Happy*/}
					{/* <ImageEvent
						date='October 2020'
						className='text-center '
						text='Happy'
						//src={L_CRYPTODASH}
						alt='Happy'>
						<div className='d-flex justify-content-between flex-column mt-1'>
							<div>
								<Accordion>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey='0'
											className='p-2 text-center accordian-main'>
											PROJECT DETAILS
										</Accordion.Toggle>

										<Accordion.Collapse eventKey='0' className='text-left'>
											<Card.Body>
												<strong>Description:</strong>Happy is a project that
												aims to bring people together to visit children and
												teenagers in orphanages in order to make their days more
												joyful.
												<hr />
												<strong>Features:</strong>
												<ul className='list-styles pt-1'>
													<li>Powered by React.js </li>
													<li>Axios</li>
													<li>Node.js and Express for route management</li>
													<li>TypeORM</li>
													<li>SQLite</li>
													<li>React-Native</li>
													<li>Expo</li>
												</ul>
												<hr />
												<strong>Tech used:</strong>
												<div className='container'>
													<div>
														<Image
															src={L_TYPESCRIPT}
															alt='Typescript.js'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_REACT}
															alt='React.js'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_CSS3}
															alt='CSS'
															rounded
															className='image-style m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_NODE_JS}
															alt='Node.js'
															rounded
															className='image-style-1 m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_EXPRESS}
															alt='Express'
															rounded
															className='image-style-1 m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_SQLite}
															alt='SQLite'
															rounded
															className='image-style-1 m-1'></Image>{" "}
													</div>
													<div>
														<Image
															src={L_HEROKU}
															alt='Heroku'
															rounded
															className='image-style-1 m-1'></Image>{" "}
													</div>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
							<div className='d-flex justify-content-between flex-nowrap text-center'>
								<UrlButton
									href='https://cryptodash-live.herokuapp.com/'
									target='_blank'>
									SEE LIVE
								</UrlButton>
								<UrlButton
									href='https://github.com/francianepovoa/cryptodash'
									target='_blank'>
									SOURCE CODE
								</UrlButton>
							</div>
						</div>
					</ImageEvent> */}
				</Events>
			</Timeline>
		</div>
	);
};

export default TimeLine;
